<template>
  <reactive-base
      app="member_index"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <send-mail v-if="sendMail" @sendMail="doSendMail" @closed="closeSendMail"></send-mail>
    <save-search v-if="saveSearch" @saveSearch="doSaveSearch" @closed="closeSaveSearch"></save-search>

    <export-members @beginMemberExport="exportMemberResults" @closed="closeExportMembers" :exportEstimate="exportEstimate"
      v-if="exportEstimate"></export-members>

    <state-provider>
      <div class="col-12" :includeKeys="['value', 'hits', 'aggregations', 'error']" slot-scope="{ searchState }">
      

          <div class="row justify-content-between" v-if="searchState && $route.name == 'members.members'">
            
            <div class="col-md-2">
              <div class="widget">
                <div class="widget-header compact">
                  <div class="widget-header-label">
                    <h3>{{ $t('labels.physical-persons') }}</h3>
                  </div>
                </div>
                <div class="widget-body pt-3 pb-4">
                  <div class="row">
                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl"> 
                        {{ getBucketCount(searchState, 'physicalPersonFilter', 'physical_person.raw', 'Fysisk person') }}
                      </div>
                      <div class="label" style="color: rgb(208, 23, 6); background-color: rgba(208, 23, 6, 0.1);">
                        {{ $t('labels.physical-persons') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div class="col-md-2">
              <div class="widget">
                <div class="widget-header compact">
                  <div class="widget-header-label">
                    <h3>{{ $t('labels.legal-entities') }}</h3>
                  </div>
                </div>
                <div class="widget-body pt-3 pb-4">
                  <div class="row">
                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'genderSubFilter', $field('gender') + '.raw', locale ? 'Juridisk person' : 'Gender-fluid') }}
                      
                      </div>
                      <div class="label" style="color: rgb(134, 193, 0); background-color: rgba(134, 193, 0, 0.1);">
                        {{ $t('labels.legal-entities') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="widget">
                <div class="widget-header compact">
                  <div class="widget-header-label">
                    <h3>{{ $t('labels.supporters') }}</h3>
                  </div>
                </div>
                <div class="widget-body pt-3 pb-4">
                  <div class="row">
                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'isSupportFilter', 'is_support.raw', 'Ja') }}
                            
                      
                      </div>
                      <div class="label" style="color: rgb(21, 14, 255); background-color: rgba(21, 14, 255, 0.1);">
                        {{ $t('labels.supporters') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
  

          <div class="col-md-6" >
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>{{ $t('labels.total') }}</h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">

                  <div class="col text-center br-1">
                          <div class="text-big text-big-xl">
                            {{ getBucketCount(searchState, 'activeMemberFilter', 'active_users.raw', 'Ja') }}
                          </div>
                          <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                            {{ $t('labels.active-members') }}
                          </div>
                        </div>

                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'activeMinervaFilter', 'active_minerva.raw', 'Ja') }}
                     
                      </div>
                      <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                        {{ $t('labels.users-minerva') }}
                      </div>
                    </div>

                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'SearchResult', 'id', 'total') }}
                    </div>
                    <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                      {{ $t('labels.total-members') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-between" v-if="searchState && $route.name == 'members.board'">
             <div class="col-md-8" >
                <div class="widget">
                  <div class="widget-header compact">
                    <div class="widget-header-label">
                      <h3>{{ $t('labels.trusted-members') }}</h3>
                    </div>
                  </div>
                  <div class="widget-body pt-3 pb-4">
                    <div class="row">
                      <div class="col text-center br-1">
                        <div class="text-big text-big-xl">
                          {{ getBucketCount(searchState, 'isBoardMemberFilter', 'is_board_member.raw', 'Ja') }}
                      
                        </div>
                        <div class="label" style="color: rgb(93, 93, 93); background-color: rgba(93, 93, 93, 0.1);">
                          {{ $t('labels.board-members') }}
                        </div>
                      </div>
                      <div class="col text-center br-1">
                        <div class="text-big text-big-xl">
                          {{ getBucketCount(searchState, 'isNominationCommitteeOrAuditorFilter', 'is_nomination_committee_or_auditor.raw', 'Ja') }}
                     
                        </div>
                        <div class="label" style="color: rgb(0, 142, 102); background-color: rgba(0, 142, 102, 0.1);">
                          {{ $t('labels.nomination-committee-auditors') }}
                        </div>
                      </div>
                      <div class="col text-center br-1">
                        <div class="text-big text-big-xl">
                          {{ getBucketCount(searchState, 'isSupportFilter', 'is_support.raw', 'Ja') }}
                      
                        </div>
                        <div class="label" style="color: rgb(21, 14, 255); background-color: rgba(21, 14, 255, 0.1);">
                          {{ $t('labels.supporters') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
              <div class="col-md-4" >
                <div class="widget">
                  <div class="widget-header compact">
                    <div class="widget-header-label">
                      <h3>{{ $t('labels.total') }}</h3>
                    </div>
                  </div>
                  <div class="widget-body pt-3 pb-4">
                    <div class="row">
                      <div class="col text-center br-1">
                          <div class="text-big text-big-xl">
                            {{ getBucketCount(searchState, 'activeMinervaFilter', 'active_minerva.raw', 'Ja') }}
                          </div>
                          <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                            {{ $t('labels.users-minerva') }}
                          </div>
                      </div>
                      <div class="col text-center br-1">
                        <div class="text-big text-big-xl">
                          {{ getBucketCount(searchState, 'SearchResult', 'id', 'total') }}
                        </div>
                        <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                          {{ $t('labels.total-members') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-between" v-if="searchState && $route.name == 'members.coordinators'">
          <div class="col-md-10">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>{{ $t('labels.coordinator') }}</h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1" v-for="status in coordinatorsStatus" :key="status.id">
                    <div class="text-big text-big-xl">
                      {{ getNestedBucketCount(searchState, 'coordinatorSubFilter', $field('coordinator.label')+'.raw', $to(status,'label')) }}
                    </div>
                    <div class="label" :style="{ color: status.color, backgroundColor: status.color + '1A' }">
                      {{ $to(status,'label') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2" >
              <div class="widget">
                <div class="widget-header compact">
                  <div class="widget-header-label">
                    <h3>{{ $t('labels.total') }}</h3>
                  </div>
                </div>
                <div class="widget-body pt-3 pb-4">
                  <div class="row">
                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'SearchResult', 'id', 'total') }}
                      </div>
                      <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                        {{ $t('labels.total-members') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

          
        <div class="row justify-content-between" v-if="searchState && $route.name == 'members.support'">
          <div class="col-md-8">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>{{ $t('labels.supporters') }}</h3>
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1" v-for="supportType in supportTypes" :key="supportType.id">
                    <div class="text-big text-big-xl">
                      {{ getNestedBucketCount(searchState, 'supportSubFilter', $field('support.label')+'.raw', $to(supportType,'label')) }}
                    </div>
                    <div class="label" :style="{ color: supportType.color, backgroundColor: supportType.color + '1A' }">
                      {{ $to(supportType,'label') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" >
              <div class="widget">
                <div class="widget-header compact">
                  <div class="widget-header-label">
                    <h3>{{ $t('labels.total') }}</h3>
                  </div>
                </div>
                <div class="widget-body pt-3 pb-4">
                  <div class="row">
                     <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'activeMinervaFilter', 'active_minerva.raw', 'Ja') }}
                      </div>
                      <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                        {{ $t('labels.users-minerva') }}
                      </div>
                    </div>
                    <div class="col text-center br-1">
                      <div class="text-big text-big-xl">
                        {{ getBucketCount(searchState, 'SearchResult', 'id', 'total') }}
                      </div>
                      <div class="label" style="color: rgb(181, 2, 149); background-color: rgba(181, 2, 149, 0.1);">
                        {{ $t('labels.total-members') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </state-provider>
    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label" v-if="title.length !== 0">
            <h3>{{ title }}</h3>
          </div>
          <div class="widget-header-toolbar">
            <div class="actions">
              <div
                  class="ml-3"
                  v-for="facet in getFacetsByLevel(1)"
                  :key="facet.id"
                  :class="{ 'filter-action-sm': facet.small, 'filter-action': facet.type === 'list', 'search-action': facet.type === 'search' }"
              >
                <multi-dropdown-list
                    v-if="facet.type === 'list' && checkFacetPermission(facet.permission)"
                    :placeholder="facet.placeholder"
                    :componentId="facet.id"
                    :URLParams="true"
                    sortBy="asc"
                    :nestedField="facet.nestedField"
                    fuzziness="AUTO"
                    searchPlaceholder="Sök..."
                    :size="100"
                    :showSearch="true"
                    :filterLabel="facet.label"
                    :dataField="facet.field"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                />
                <data-search
                    v-if="facet.type === 'search' && checkFacetPermission(facet.permission)"
                    :componentId="facet.id"
                    :dataField="facet.field"
                    iconPosition="right"
                    className="data-search"
                    :showClear="true"
                    :URLParams="true"
                    :placeholder="facet.placeholder"
                    :debounce="100"
                    fuzziness="AUTO"
                    :autosuggest="false"
                    :filterLabel="facet.label"
                />

                <toggle-button
                  v-if="facet.type === 'toggle' && checkFacetPermission(facet.permission)"
                  class="filter-action active-users-toggle"
                  :componentId="facet.id"
                  :dataField="facet.field"
                  :data="[{ label: facet.label, value: 'Ja' }]"
                  :filterLabel="facet.label"
                  :multiSelect="false"
                  :showFilter="true"
                  :URLParams="true"
                  :react="{ and: getOtherFilterIds(facet.id) }"
                />
              </div>
              <div class="btn btn-grey btn-bold ml-3" @click="toggleFilter" v-show="!showFilter"><i
                  class="fas fa-filter"></i></div>
              <div class="btn btn-success btn-bold ml-3" @click="toggleFilter" v-show="showFilter"><i
                  class="fas fa-filter"></i></div>
            </div>
          </div>
        </div>

        <div class="widget-filter bb-1" v-show="showFilter">
          <div class="row search-filters">
            <div class="col-2 mb-4" v-for="facet in getFacetsByLevel(2)" :key="facet.id">
              <multi-dropdown-list
                  v-if="facet.type === 'list' && checkFacetPermission(facet.permission) && facet.id === 'qualificationSubFilter'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :size="100"
                  :showSearch="true"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :react="{ and: getOtherFilterIds(facet.id) }"
                  :transformData="filterMemberQualifications"
              />

              <multi-dropdown-list
                  v-if="facet.type === 'list' && checkFacetPermission(facet.permission) && facet.id !== 'qualificationSubFilter'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :size="100"
                  :showSearch="true"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :react="{ and: getOtherFilterIds(facet.id) }"
              />

              <data-search
                  v-if="facet.type === 'search' && checkFacetPermission(facet.permission)"
                  :dataField="[facet.field]"
                  iconPosition="right"
                  className="data-search"
                  :showClear="true"
                  :URLParams="true"
                  fuzziness="AUTO"
                  :filterLabel="facet.label"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :debounce="100"
                  :autosuggest="true"
              />

              <toggle-button
                  v-if="facet.type === 'toggle' && checkFacetPermission(facet.permission)"
                  class="active-users-toggle"
                  :componentId="facet.id"
                  :dataField="facet.field"
                  :data="[{ label: facet.label, value: 'Ja' }]"
                  :filterLabel="facet.label"
                  :multiSelect="false"
                  :showFilter="true"
                  :URLParams="true"
                  :react="{ and: getOtherFilterIds(facet.id) }"
                />
            </div>
          </div>
        </div>
        <div class="widget-body no-padding">
          <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
        </div>
        <div class="widget-body no-padding">
          <ReactiveComponent componentId="DataControllerSensor">
            <div slot-scope="{ setQuery }">
              <custom-member-results :setQuery="setQuery" :query="query"/>
            </div>
          </ReactiveComponent>
          <reactive-list
              @pageChange="pageChange"
              :showResultStats="false"
              componentId="SearchResult"
              :dataField="getSortBy"
              :sortBy="getSortByOrder ? 'desc' : 'asc'"
              className="result-list-container"
              :pagination="true"
              :URLParams="true"
              :from="0"
              :size="15"
              @queryChange="getQueryChange"
              :react="{ and: getOtherFilterIds('foo') }"
          >
            <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data }">
              <thead v-if="data.length !== 0">
              <tr>
                <th
                    v-for="field in fields"
                    :key="field.key"
                    :width="field.width + '%'"
                    :class="{ 'sort-up': isSortField(field.sort_field, true), 'sort-down': isSortField(field.sort_field, false) }"
                    @click="changeSortBy(field.sort_field)"
                >
                  {{ field.label }}
                </th>
              </tr>
              </thead>
              <tbody v-for="item in data" :key="item.id">
              <router-link tag="tr" :to="'/members/member/' + item.id + '/overview'" :class="{ 'cursor-pointer': true, }">
                <td v-for="field in fields" :key="'table-' + item.id + '-' + field.key"><status-icon v-if="field.key == 'id'" :member="item" style="position: absolute; left:5px; "></status-icon>
                  {{ field.key !== 'email' ? item[field.key] : '' }}
                  <button v-if="field.key === 'email'" :href="'mailto:' + item[field.key]" class="h-100 btn btn-light">
                    <i class="mt-2 fas fa-envelope opacity-40"></i>
                  </button>
                </td>
              </router-link>
              </tbody>
            </table>

            <template slot="renderNoResults">
              <div class="empty-placeholder pt-5">
                <i class="far fa-times-circle"></i>
                {{ $t('labels.no-results-found') }}
              </div>
            </template>
          </reactive-list>
        </div>
      </div>
    </div>
  </reactive-base>
</template>
<script>
import axios from 'axios';
import BojAPI from '@/api/boj';
import SendMail from '@/components/forms/SendMail';
import SaveSearch from '@/components/forms/SaveSearch';
import CustomMemberResults from '@/components/lists/member/CustomMemberResults';
import ExportMembers from '@/components/member/ExportMembers';
import StatusIcon from '@/components/member/StatusIcon';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    CustomMemberResults,
    SendMail,
    SaveSearch,
    ExportMembers,
    i18n,
    StatusIcon,
  },
  props: {
    query: Object,
    facets: Array,
    title: String,
    fields: Array,
    noWidgetBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      exportEstimate: false,
      sendMail: false,
      saveSearch: false,
      latestQuery: '',
      getSortByOrder: true,
      getSortBy: '_score',
      showFilter: false,
      template: '',
    };
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder == 'true';
    }
  },

  computed: {
    coordinatorsStatus() {
      return this.$store.getters.memberOptions.coordinators;
    },
    supportTypes() {
      return this.$store.getters.memberOptions.support_types;
    },
    user() {
      return this.$store.getters.user;
    },
    currentPage() {
      return this.$route.name
    },
    locale() {
      return i18n.locale == 'sv' ? true : false;
    },
  },
  methods: {
    filterMemberQualifications(data) {
      return data;
    },
    doSaveSearch(payload) {
      BojAPI.saveSearch(this.latestQuery, payload.label, 'member', payload.global).then((response) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.search-saved'),
          type: 'success',
          duration: 3000,
        });
      });
    },
    doSendMail(payload) {
      BojAPI.sendBulkMailES(this.latestQuery, payload.subject, payload.body, payload.signature, 'member').then((response) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.mail-sent'),
          type: 'success',
          duration: 3000,
        });
      });
    },
    closeSaveSearch() {
      this.saveSearch = false;
    },
    closeSendMail() {
      this.sendMail = false;
    },
    openSendMail() {
      this.sendMail = true;
    },

    openSaveSearch() {
      this.saveSearch = true;
    },

    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

    /**
     * Toggle Export modal.
     */
    closeExportMembers() {
      this.exportEstimate = null;
      this.template = null;
    },

    /**
     * Retrieve an estimate before actually exporting tickets.
     */
    exportMemberResultsEstimate(template) {
      BojAPI.exportMembersFromElasticEstimate(this.latestQuery).then((response) => {
        this.exportEstimate = response.data;
        this.template = template;
      });
    },

    /**
     * Begin export of tickets.
     */
    exportMemberResults() {
      this.exportEstimate = null;

      BojAPI.exportMembersFromElastic(this.latestQuery, this.template).then((response) => {
      });
    },

    /**
     * Export results to PDF
     *
     * Uses Axios to retrieve the file.
     */
    exportResults(template, format) {
      this.isDownloading = true;
      this.$emit('downloadStatus', true);
      BojAPI.exportCurrentSearch('members', this.latestQuery, template, format).then((response) => {
        this.forceFileDownload(response, format, template);
      });
    },

    /**
     * Force file download using result from Axios.
     */
    forceFileDownload(response, format, template) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let filename = '';
      if (template == 'members') {
        filename = 'Allmän information';
      }
      if (template == 'address') {
        filename = 'Adressetiketter';
      }

      filename += '.' + (format == 'excel' ? 'xls' : 'pdf');

      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      this.$emit('downloadStatus', false);
    },

    /**
     *
     * @param url
     * @param format
     */
    downloadWithAxios(url, format) {
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
      })
          .then((response) => {
            this.forceFileDownload(response, format);
          })
          .catch(() => console.log('error occured'));
    },

    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },
    getFacetsByLevel(level) {
      return this.facets.filter((a) => a.level == level);
    },

    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id != id).map((a) => a.id);
      ids.push('DataControllerSensor');
      return ids;
    },
    pageChange() {
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    updateQueryStringParam(key, value) {
      var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
          urlQueryString = document.location.search,
          newParam = key + '=' + value,
          params = '?' + newParam;

      if (urlQueryString) {
        var keyRegex = new RegExp('([?&])' + key + '[^&]*');

        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
    getBucketCount(searchState, componentId, aggregationId, key, aggregationIdLabel) {
      if (searchState[componentId] === undefined) {
        return 0;
      }

      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {

        let aggregation = searchState[componentId].aggregations[aggregationId];

        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);
    
          if (buckets) {
            return buckets.doc_count && buckets.doc_count > 0 ? buckets.doc_count.toLocaleString('sv-SE')  : 0;
          } else {
            return 0;
          }

        } else if (Object.prototype.hasOwnProperty.call(aggregation, 'doc_count')) {
          let buckets = searchState[componentId].aggregations[aggregationId].doc_count;
          
          if (buckets) {
            return buckets;
          } else {
            return 0;
          }

        } else {
          return 0;
        }
      } else if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'hits')) {
        let hits = searchState[componentId].hits;
        if (Object.prototype.hasOwnProperty.call(hits, 'total')) {
          return searchState[componentId].hits.total.toLocaleString('sv-SE')
        } else {
          return 0;
        }
      }
      return 0;
    },
    getNestedBucketCount(searchState, componentId, aggregationId, key) {
      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations.reactivesearch_nested[aggregationId];
        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations.reactivesearch_nested[aggregationId].buckets.find((item) => item.key === key);
          if (buckets) {
            return buckets.doc_count ? buckets.doc_count.toLocaleString('sv-SE') : 0;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
      return 0;
    },
  },
};
</script>
